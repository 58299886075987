import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NguCarouselModule } from '@ngu/carousel';
import { IntercomModule } from 'ng-intercom';

import { StoreModule } from '@arenaops/arena.ux/store';
import { AppModule as ArenaAppModule } from '@arenaops/arena.ux/components/app';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { GlobalErrorHandler } from './core/providers/error.handler';
import { StartupService } from './core/services/startup.service';
import { startupServiceFactory } from './core/factories/startup';

import { environment } from 'src/environments/local';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    StoreModule.forRoot({
      production: environment.production,
      project: 'Apparel',
      platform: 'Web',
      apiURL: environment.apiUrl,
      signInURL: '',
    }),
    IntercomModule.forRoot({
      appId: environment.appId, // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),
    NguCarouselModule,
    // Arena UX
    ArenaAppModule,
  ],

  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      multi: true,
      deps: [StartupService]
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
